:root {
  --digitalSensorLuxeVwBase: 100vw;
  --digitalSensorLuxeVhBase: 100vh;
  --digitalSensorLuxeBase: 16px;
  @media screen and (min-width: 300px) {
    --digitalSensorLuxeBase: calc(20 * (100vw / 700));
    // --digitalSensorLuxeBase: calc(16px + (20 - 16) * ((100vw - 320px) / (768 - 320)));
  }
  @media screen and (min-width: 700px) {
    --digitalSensorLuxeBase: calc(40 * (100vw / 2560));
    // --digitalSensorLuxeBase: calc(20px + (40 - 20) * ((100vw - 768px) / (2400 - 768)));
  }
  @media screen and (min-width: 2560px) {
    --digitalSensorLuxeBase: 40px;
  }
}

.hide-desktop {
  @media only screen and (min-width: 700px) {
    display: none;
  }
}

.hide-mobile {
  @media only screen and (max-width: 699px) {
    display: none;
  }
}

// svg {
//   width: 100%;
//   height: 100%;
// }

.digital-sensor-luxe-page {
  .topNav__logoImg {
    display: none;
  }

  .topNav__logoImg--var3 {
    display: block;
  }
  .topNav__skewBar {
    // display: none;
  }

  .digitalSensorLuxeFooter > .parallax {
    display: none;
  }

  .youtubePlayer--full {
    @media (min-width: 1200px) {
      // 1200px and up
      height: calc(100vh);
    }
  }

  .wave {
    animation: wave 4s ease-in-out infinite;
    animation-delay: calc(var(--i) * 0.25);
    // animation-name: wave;
    // // animation-duration: 10s;
    // animation-timing-function: linear;
    // animation-direction: alternate;
    // animation-iteration-count: infinite;
    // // animation-fill-mode: forwards;
    // // animation-play-state: running;
  }

  @keyframes wave {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0px);
    }
    //   0% {
    //     transform: matrix(1, 0, 0, 1, -200, 0);
    //   }
    //   100% {
    //     transform: matrix(1, 0, 0, 1, +200, 0);
    //   }
  }

  @keyframes marquee {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 1000vw;
    }
  }
}


.youtubeVideoPanel_digitalSensor .youtubePlayer{
  //min-height: 460px;
}
