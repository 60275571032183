header.topNav2 {
  font-size: 0.8rem;
  @media screen and (min-width: 576px) {
    height: 3rem;
  }
}
.horizontal_menu {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.horizontal_menu .horizontal_menu_ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 92%;
  @media screen and (min-width: 1200px) {
    width: 80%;
    padding-left: 0;
  }
  @media screen and (min-width: 1600px) {
    width: 77%;
  }
  @media screen and (min-width: 1920px) {
    width: 62%;
  }
  @media screen and (min-width: 2500px) {
    width: 58%;
  }
}
.horizontal_menu_ul li {
  list-style: none;
  font-weight: 700;
}
.horizontal_subMenu li {
  font-weight: 400;
}
.horizontal_menu_link a,
.horizontal_menu_link span,
.navBar__subItem a {
  color: #fff;
  text-decoration: none;
  @media screen and (min-width: 1600px) {
    letter-spacing: 1px;
  }
  @media screen and (min-width: 2500px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 0.75rem;
  }
  @media screen and (max-width: 1024px) {
    font-size: 0.6rem;
  }
}
.horizontal_menu_link a:hover,
.navBar__subItem a:hover {
  color: #c20f2f;
}
.horizontal_menu_item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.horizontal_menu_item:hover .horizontal_subMenu {
  height: auto;
  background-color: #222222;
  visibility: visible;
  opacity: 1;
}
.horizontal_subMenu {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  padding: 25px 0;
  min-width: 200px;
  max-width: 302px;
  width: max-content;
  text-align: left;
  border-top: none;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  @media screen and (max-width: 1024px) {
    padding: 15px 0;
    min-width: 70px;
    max-width: 125px;
  }
  @media screen and (max-width: 900px) {
    padding: 13px 0;
    min-width: 70px;
    max-width: 100px;
  }
}
.navBar__subItem {
  padding-left: 25px;
  padding-right: 25px;
  line-height: 1.5;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    padding-left: 13px;
    padding-right: 6px;
  }
  @media screen and (max-width: 900px) {
    padding-left: 9px;
    padding-right: 6px;
  }
}
.horizontal_subMenu:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  background: #c20f2f;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.6s;
}
.horizontal_menu_item:hover .horizontal_subMenu:before {
  transform: scaleX(1);
  transition: transform 0.6s;
}
.horizontal_menu_item:hover .horizontal_menu_link a {
  color: #c20f2f;
  transition: transform 0.3s;
}

// search bar
.search_container {
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1200px) {
    right: 5rem;
  }
  @media screen and (min-width: 1600px) {
    right: 8rem;
  }
  @media screen and (min-width: 1920px) {
    right: 18rem;
  }
  @media screen and (min-width: 2500px) {
    right: 28rem;
  }
}
.search_container svg path {
  stroke: #fff;
}
.search_container:hover svg path {
  stroke: #c20f2f;
}
.searchBar3 {
  width: 100%;
  height: auto;
  transform: translate(-100%);
  transition: all 0.3s ease-in-out;
  background: #222;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  gap: 1rem;
  overflow: hidden;
}
.search_container_open + .searchBar3 {
  transform: translate(0);
  transition: all 0.3s ease-in-out;
}
.searchBar3 > form {
  width: 55%;
  margin: 3.5rem 0;
  @media screen and (min-width: 1200px) {
    width: 60%;
  }
  @media screen and (min-width: 1600px) {
    width: 60%;
  }
  @media screen and (min-width: 1920px) {
    width: 51%;
  }
  @media screen and (min-width: 2500px) {
    width: 48%;
  }
}
.searchBar3 svg {
  color: #fff;
}
.searchBar3 svg:hover {
  color: #c20f2f;
}
