@font-face {
  font-family: 'Gotham';
  src: url('/dist/fonts/Gotham-Bold.woff2') format('woff2'),
      url('/dist/fonts/Gotham-Bold.woff') format('woff'),
      url('/dist/fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/dist/fonts/Gotham-Medium.woff2') format('woff2'),
      url('/dist/fonts/Gotham-Medium.woff') format('woff'),
      url('/dist/fonts/Gotham-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('/dist/fonts/Gotham-Book.woff2') format('woff2'),
      url('/dist/fonts/Gotham-Book.woff') format('woff'),
      url('/dist/fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('/dist/fonts/DINNextLTPro-LightCondensed.woff2') format('woff2'),
      url('/dist/fonts/DINNextLTPro-LightCondensed.woff') format('woff'),
      url('/dist/fonts/DINNextLTPro-LightCondensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('/dist/fonts/DINNextLTPro-BoldCondensed.woff2') format('woff2'),
      url('/dist/fonts/DINNextLTPro-BoldCondensed.woff') format('woff'),
      url('/dist/fonts/DINNextLTPro-BoldCondensed.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}