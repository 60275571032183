@import './shop/storeList';
@import './product/productFilterSort';
@import './product/productList';
@import './product/productView';
@import './blog/blogList';
@import './blog/blogView';
@import './file/fileLink';
@import './search';

.multiColumnPanel .htmlPanel {
  padding: 0 1em;
}

.slick-slide.slick-current {
  z-index: 2;
}