.productList__warpper {
  display: flex;
  margin-right: -10px;
}

.p__filter__warpper {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 10px;
  padding-left: 0px;
  width: 20%;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  //will be removed
  &.none_dryers {
    display: none;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .filter__by {
    text-align: left;
    background-color: gray;
    margin-bottom: 0.7rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    font-weight: 700;
    color: white;
    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1.1rem;
    }
  }

  .filter__title {
    font-size: 0.9rem;
    font-weight: 700;
    text-align: left;
    padding-left: 8px;

    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 1.1rem;
    }
  }

  .form-group {
    padding-left: 8px;
    margin-bottom: 0px;
  }

  .filter__line {
    color: gray;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .form-check {
    padding-left: 0px;

    .form-check-input {
      margin-left: 0px;
      left: 0;
      margin-top: 7px;

      @include media-breakpoint-up(xl) {
        margin-top: 11px;
      }

      @include media-breakpoint-up(xxl) {
        margin-top: 10px;
      }
    }

    .form-check-label {
      text-align: left;
      //width: 100%;
      //max-width: 166px;
      margin-left: 20px;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      display: block;
      font-size: 0.8rem;

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.1rem;
      }
    }
  }
}

.p__filter__warpper + .productList__container {
  flex: 0 0 100%;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    flex: 0 0 80%;
    max-width: 80%;
  }

  //will be removed
  &.none_dryers {
    flex: 0 0 100%;
    max-width: 100%;
    @include media-breakpoint-up(lg) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.productList {
  // @include tile--titleOnly(
  //   $breakpoints: (
  //     xs: 2,
  //     sm: 2,
  //     md: 3,
  //     lg: 3,
  //     xl: 4,
  //     xxl: 5,
  //   ),
  //   $ratio: 100%
  // );

  @include tile--titleOnly(
    $breakpoints: (
      xs: 2,
      sm: 2,
      md: 3,
      lg: 3,
      xl: 3,
      xxl: 4,
    ),
    $ratio: 100%
  );

  //flex : 0 0 80%;
  //max-width: 80%;
  margin-left: -10px;
  margin-right: 0px;

  @include media-breakpoint-up(lg) {
    margin-left: 0px;
  }

  //will be removed
  &.none_dryers {
    @include tile--titleOnly(
      $breakpoints: (
        xs: 2,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 4,
        xxl: 5,
      ),
      $ratio: 100%
    );
    margin-left: -10px;
    margin-right: -10px;
  }
}

.productTile__link {
  background: #fff;
  box-shadow: 1px 1px 5px 0px #e0e0e0;
  position: relative;
  overflow: hidden;
  text-align: center;

  &:before {
    transition: all 0.4s ease-in-out;
    content: "";
    background: rgba(#000, 0.05);
    width: 100%;
    height: 100%;
    left: 65%;
    top: 0;
    transform: skewX(-12deg) translateX(-200%);
    pointer-events: none;
    position: absolute;
    z-index: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &:before {
      transform: skewX(-12deg) translateX(-100%);
    }
  }
}

.productTile__title {
  flex-grow: 1;
  font-size: 0.75rem;
  padding: 0 1rem;
}

.productTile__inlineRating {
  margin-top: 0.3rem;
}

.productTile__price {
  color: $font-color;
  font-size: 0.75rem;
  padding: 0.5rem;
}

.productTile__inlineRating
  [data-bv-show="inline_rating"]
  .bv_main_container
  .bv_averageRating_component_container {
  display: none !important;
}

.prdFilterSort {
  display: flex;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  //will be removed
  &.none_dryers {
    display: flex;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}

.filter__by {
  text-align: left;
  background-color: gray;
  margin-bottom: 0.7rem;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: white;
  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 1.1rem;
  }
}
