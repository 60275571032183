%skewButton {
  display: inline-block;
  font-family: $family-header;
  font-size: 1.6rem;
  background: $primary-color;
  padding: 0.4rem 1.2rem;
  line-height: 1;
  color: white;
  transition: all 0.3s;

  transform: skewX(-12deg);

  span {
    text-align: center;
    display: inline-block;
    transform: skewX(12deg);
    width: 100%;
  }


  &:hover,
  &:focus {
    text-decoration: none;
    background: darken($primary-color, 10);
  }

}