$top-bar-z-index: 1000 !default;
$top-bar-height: (
  mobile: 2rem,
  desktop: 2rem,
) !default;

.topNav__logo {
  @extend %topNavMarginTop;
  position: fixed;
  top: map-get($top-bar-height, mobile);
  z-index: $top-bar-z-index + 2;

  img {
    width: 80px;
  }
  @media screen and (min-width: 820px) {
    top: map-get($top-bar-height, desktop);
  }
  @include media-breakpoint-up(lg) {
    top: map-get($top-bar-height, desktop);
    left: 1rem;

    img {
      width: 160px;
    }
  }
  @media screen and (min-width: 1200px) {
    left: 1rem;
  }
  @media screen and (min-width: 1366px) {
    left: 2rem;
  }
  @media screen and (min-width: 1920px) {
    left: 4rem;
  }
}

.topNav__skewBar {
  position: fixed;
  @extend %topNavMarginTop;
  background: linear-gradient(rgba(#fff, 0.2), rgba(#fff, 0));
  width: 100%;
  height: 100%;
  left: 110px;
  transform: skewX(-12deg) translateX(-100%);
  transform-origin: top left;
  z-index: $top-bar-z-index + 1;
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    left: 330px;
  }
}
