.socials {
  margin: 30px 0 15px 0;

  a {
    width: 50px;
    height: 50px;

    margin-left: 8px;
    margin-right: 8px;
    
    //overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat; 
    transition: .3s;
    display: inline-block;

    @include media-breakpoint-up(sm){
      margin-left: 20px;
      margin-right: 20px;
    }

    //margin: 8px;
    // img {
    //   width: 45px;
    // }
  }
  a:hover{
    background-position: 0 0;
  }
}

.socials__facebook {
  background-image: url('/assets/social/facebook.png');
}
.socials__instagram {
  background-image: url('/assets/social/instagram.png');
}
.socials__youtube {
  background-image: url('/assets/social/youtube.png');
}
.socials__pinterest {
  background-image: url('/assets/social/pinterest.png');
}
.socials__tiktok {
  background-image: url('/assets/social/tiktok.png');
  margin-left: 2px !important;
  @include media-breakpoint-up(sm){
    margin-left: 15px !important;
  }
}