.productViewPanel {
  text-align: left;
  word-break: normal;
  .container {
    max-width: 1200px;
    margin: auto;
  }

  // .right {
  //   display: flex;
  //   flex-direction: column;
  // }
}

.productView {
  margin-bottom: 3rem;
}

.productView__title {
  line-height: 1;
  margin: 0 0 0.5rem;
  font-size: 3rem;

  @include media-breakpoint-up(lg) {
    font-size: 4rem;
  }
}

.productView__price {
  font-size: 1.4rem;
  font-weight: bold;
}

.productView__discontinued {
  font-size: 1.3rem;
  font-style: italic;
  color: red;
  margin: 1rem 0;
}

.productView__productCode {
}

.productView__intro {
  white-space: pre-line;
  margin: 1.2rem 0;
}

a.productView__skewButton {
  @extend %skewButton;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.productView__images {
  // display: flex;
  // flex-wrap: wrap;

  padding: 10px 40px;
  margin-bottom: 1rem;

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    background: #ccc;
    padding: 9px 13px;
    border-radius: 50%;

    &:before {
      display: inline-block;
      width: 8px;
      font-size: 8px;
    }
  }

  .slick-prev {
    left: -40px;

    &:before {
      content: url("/assets/icons/chevron-left-solid.svg");
      transform: translate(-2px, 0);
    }
  }

  .slick-next {
    right: -40px;

    &:before {
      content: url("/assets/icons/chevron-right-solid.svg");
    }
  }
}

// .productView__image {
//   // flex: 0 1 33.33%;
//   padding: 15px;

//   img {
//     cursor: pointer;
//   }
// }

.productView__hero {
  cursor: zoom-in;
}

// div version
.productView__image {
  // flex: 0 1 33.33%;
  padding: 15px;
  cursor: pointer;
  position: relative;
  outline: none;
  &:before {
    opacity: 0;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    transition: all 0.5s;
    background: transparent;
    z-index: -1;
  }
  &.current:before {
    background: rgba(#000, 0.1);
    opacity: 1;
  }
}

.productView__imageBg {
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &:before {
    content: "";
    height: 0;
    padding-bottom: 100%;
    display: block;
  }
}

.productView__packages {
  display: flex;
  flex-wrap: wrap;
  //margin-top: auto;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.productView__package {
  flex: 0 1 50%;
  max-width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }
}

.productView__package__s_container {
  max-width: 160px;
  @include media-breakpoint-up(sm) {
    max-width: 160px;
  }
}

.productView__package__special {
  max-width: 160px;
  margin: auto;
}

.productView__canstar {
  //flex: 0 1 36%;
  flex: 0 1 50%;

  @include media-breakpoint-up(sm) {
    //max-width: 240px;
    max-width: 320px;
  }
}

.productView__linkItem {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.productView__linkItem--instagram {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.productView__linkItem--youtube {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.productView__linkItem--tiktok {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.productView__empty {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  height: 0;
  & > div {
    min-width: 320px;
  }
}

.productView__linkItem--page {
  background-color: #fff;
  color: $font-color;
  border: 1px solid $font-color;
  border-radius: 0;
  font-size: 0.8rem;
  letter-spacing: 1px;
  transition: all 0.15s;
  padding: 0.5rem 1rem;
  text-align: center;
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $font-color;
    color: white;
  }
}

.productView__relatedProducts {
  margin: 2rem 0;
}

@import "./productView__tabs";
@import "./imagePopup";
