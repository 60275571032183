@import '../modules//buttons/skewButton.scss';

.contentPanel {

  background: white;
  padding: 1rem 0;
  text-align: center;
  position: relative;
  word-break: break-word;

  >* {
    z-index: 1;
    position: relative;
  }

  .button {
    @extend %skewButton;
  }

  h2 .button {
    font-size: 2.6rem;
  }

  h3 .button {
    font-size: 2.2rem;
  }

  h4 .button {
    font-size: 2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:before {
    content: '';
    background: rgba(#000, 0.05);
    width: 100%;
    height: 100%;
    left: 65%;
    top: 0;
    transform: skewX(-12deg) translateX(-100%);
    pointer-events: none;
    position: absolute;
    z-index: 0;
  }

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}