.prdFilterSort {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  button {
    width: 100%;
  }
  .dropdown-menu {
    width: 100%;
    //transform: translate3d(0px, 38px, 0px) !important;
  }
}

.prdFilter {
  margin-bottom: 0.2rem;
  width: 100%;
  .prdFilter_item {
    margin-top: 0.25rem;
  }
}

.productSort {
  margin-bottom: 0.2rem;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  /*>=992*/
  .prdFilterSort {
    button {
      width: auto;
    }
    .dropdown-menu {
      width: auto;
    }
  }
  .prdFilter {
    width: auto;
    //min-width: 280px;
    flex: 1 1 auto;
    display: flex;
    margin-right: 1rem;
    .prdFilter_item {
      min-width: 220px;
      width: auto;
      margin-left: 1rem;
      margin-top: 0rem;
      &:first-of-type {
        margin-left: 0rem;
      }
    }
  }

  .productSort {
    width: auto;
    min-width: 250px;
  }
}
.no_products {
  font-size: 1.2rem;
  margin: 10px 0 0.5rem 10px;
  text-align: left;
}
