// overriding bootstrap variables in _boostrap-override.scss

$family-base: "Gotham", sans-serif;
$family-header: "DIN Next LT Pro", serif;

$weight-light: 300;
$weight-normal: 400;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-red;
$font-color: #404040;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10);

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 1.6rem,
  desktop: 3rem,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
