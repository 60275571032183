@import '../../../_scss/mixins';
@import '../../../_scss/variables';
@import "../../../_scss/bootstrap/bootstrap-override";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import '../../../_scss/modules//buttons/skewButton.scss';

.linkTiles {
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
}

.linkTile {
  flex: 1 1 100%;
  padding: 2px;
  position: relative;

  @include media-breakpoint-up(sm) {
    flex: 1 1 50%;
  }

  @include media-breakpoint-up(lg) {
    flex: 1 1 25%;
  }
}

.linkTile__bg {
  width: 100%;
}

.linkTile__text {
  position: absolute;
  bottom: 6%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
}

.linkTile__title {
  font-family: $family-header;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: white;

  @include media-breakpoint-up(sm) {/*>=576*/}
@include media-breakpoint-up(md) {/*>=768*/}
@include media-breakpoint-up(lg) {/*>=992*/}
@include media-breakpoint-up(xl) {/*>=1200*/}
@include media-breakpoint-up(xxl) {font-size: 3.5rem;}
}

.linkTile__link {
  @extend %skewButton;
  font-size: 1.2rem;
  font-weight: bold;
  &:hover,
  &:focus {
    color: white;
  }

}

.linkTile__tileLink {
  display: block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    background-color: rgba(#fff, 0);
    transition: .4s all ease;
  }

  &:hover:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    background-color: rgba(#fff, 0.15);
    transition: .4s all ease;
  }
}